.newProduct {
  flex: 4;
}

.addProductForm {
  @apply my-5;
}

.addProductItem {
  @apply flex rounded-xl border my-6;
  position: relative;
}

.addProductItem > input {
  @apply w-full p-4 px-6 border rounded-xl bg-slate-50;
  @apply focus:outline-none border-none;
}

.addProductItem > select {
  @apply w-full p-4 px-6 border rounded-xl bg-slate-50;
  @apply focus:outline-none border-none;
}

.button {
  @apply w-full bg-gradient-to-r from-green-500 to-green-800 rounded-xl shadow-xl;
  @apply py-3 text-gray-50 text-lg;
}
.button:hover {
  @apply bg-gradient-to-r from-gray-50 to-gray-100;
  @apply text-gray-700 border;
}
