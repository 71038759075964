.newProduct {
  flex: 4;
}

.addProductForm {
  @apply my-5;
}

.addProductItem {
  @apply flex rounded-xl border my-6;
  position: relative;
}

.addProductItem > input {
  @apply w-full p-4 px-6 border rounded-xl bg-slate-200;
  @apply focus:outline-none border-none;
}

.addProductItem > select {
  @apply w-full p-4 px-6 border rounded-xl bg-slate-50;
  @apply focus:outline-none border-none;
}

.button {
  @apply w-full bg-gradient-to-r from-black to-black rounded-xl shadow-xl;
  @apply py-3 text-brandColor text-lg;
}
.button:hover {
  @apply bg-gradient-to-r from-brandColor to-brandColor;
  @apply text-black border;
}
