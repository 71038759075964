@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  display: flex;
}
.second-container {
  flex: 4;
  padding: 18px;
  padding-top: 18px;
}
